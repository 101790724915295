#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.container {
    width: 100%;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.containerLogin {
    /*background: #242428;*/
    position: relative;
    margin: auto;
    height: 300px;
    width: 500px;
    max-width: 90%;
    /*border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;*/
}
